<template>
	<div style="border: 1px solid #09568d;">
		<form v-if="!isLoading" @submit.prevent="onSubmit" novalidate>
			<nav class="navbar sticky-top navbar-light bg-dark">
				<div class="container-fluid">
					<span class="navbar-brand mb-0 h6 text-light" style="">{{ vv.section.$model.length }} note section(s) in total</span>
					<div class="">
						<button type="button" class="btn btn-secondary btn-sm" style="margin-right: 20px;" @click="addMoreRow">Add More Section</button>
						<button type="submit" class="btn btn-primary btn-sm" :disabled="isProcessing">
							<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span v-if="isProcessing" style="padding-left: 15px !important;">Saving......</span>
							<span v-if="!isProcessing">Save Note</span>
						</button>
					</div>
				</div>
			</nav>
			<div class="frame bg-white" style="padding: 1rem;">
				<div class="row" style="padding: 10px 20px 10px 15px;">
					<div class="mb-2 col-6">
						<label for="">Course Title</label>
						<select class="form-select" v-model="vv.course_id.$model" disabled>
							<option :value="initialVal">Please select the course for this topic</option>
							<option v-for="(item, i) in courseList" :key="i" :value="item.course_id">{{ item.course_title }}({{ item.course_code }})</option>
						</select>
						<span class="error">{{ vv?.course_id?.$errors[0]?.$message }}</span>
					</div>
					<div class="mb-2 col-6">
						<label for="">Topic Title</label>
						<select class="form-select" v-model="vv.topic_id.$model" disabled>
							<option :value="initialVal">Please select the course for this topic</option>
							<option v-for="(item, i) in topicList" :key="i" :value="item.topic_id">{{ item.topic_title }}</option>
						</select>
						<span class="error">{{ vv?.topic_id?.$errors[0]?.$message }}</span>
					</div>
					<div class="mb-2 col-6">
						<label for="">Tutor name</label>
						<input type="text" v-model="vv.tutor_name.$model" placeholder="Enter tutor name" class="form-control">
						<span class="error">{{ vv?.tutor_name?.$errors[0]?.$message }}</span>
					</div>
					<div class="col-6">
						<div>
							<label>Tutor Image</label>
						</div>
						<img class="image" id="previewImage" :src="setImage(vv.tutor_image.$model)" alt="">
						<label class="custom-file-upload">
							<input type="file" @change="handleFileChange" id="profile-image-input" accept="image/*"/>
							Add Tutor Image
						</label><br>
						<span class="error">{{ vv?.tutor_image?.$errors[0]?.$message }}</span>
					</div>
					<table class="table align-middle">
						<thead>
							<tr>
								<th></th>
								<th style="width: 100px;">
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, i) in vv.section.$model" :key="i">
								<td>
									<h5 class="text-secondary font-weight-bold" style="font-size: 17px;">Section {{ i + 1 }}</h5>
									<div class="form-group mb-2">
										<label for="">Section title</label>
										<input type="text" v-model="item.title" placeholder="Enter section title" class="form-control">
									</div>
									<div class="form-group mb-2">
										<label for="">Section content</label>
										<ckeditor :editor="editor" v-model="item.content" :config="editorConfig"></ckeditor>
									</div>
								</td>
								<td style="">
									<button type="button" class="btn btn-danger" @click="deleteSection(i, item)" v-if="i > 0">Delete</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</form>
		<div class="card" v-if="isLoading && !showErrorPageAlert">
			<div class="card-body loader" v-if="isLoading">
				<div class="spinner-border"></div>
				<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
			</div>
		</div>
		<div class="alert alert-danger" v-if="isLoading && showErrorPageAlert" style="margin-bottom: 0px !important;">
			<h4 style="font-size: 17px !important; font-weight: bold;">{{ errorMessage }}</h4>
			<button class="btn btn-danger" @click="loadContent" style="margin-top: 7px;">Reload Content</button>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Mathematics from 'ckeditor5-math/src/math';
import { projectService } from '@/services/project.service'
import { fileMgt } from '@/services/fileMgt.service'

export default {
	name: 'NoteEditor',
	props: {
		courseId: [Number, String],
		topicId: [Number, String],
		courseList: [Array, Object],
		topicList: [Array, Object]
	},
	setup(props) {
		const fform = reactive({
			course_id: props.courseId,
			topic_id: props.topicId,
			tutor_name: '',
			tutor_image: '',
			section: [
				{
					id: null,
					title: '',
					content: ''
				}
			]
		})
		const rules = {
			course_id: { required },
			topic_id: { required },
			tutor_name: { required },
			tutor_image: { required },
			section: {}
		}
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data () {
		return {
			isLoading: true,
			isProcessing: false,
			showErrorPageAlert: false,
			errorMessage: '',
			loadingMessage: 'Loading Lecture Note',
			folder_path: '',
			initialVal: null,
			editor: ClassicEditor,
			editorConfig: {
				plugins: [
				EssentialsPlugin,
				BoldPlugin,
				ItalicPlugin,
				LinkPlugin,
				ParagraphPlugin,
				Mathematics,
				Base64UploadAdapter,
				Image,
				ImageCaption,
				ImageStyle,
				ImageToolbar,
				ImageUpload,
				ImageResize,
				Highlight,
				Alignment
				],
				toolbar: {
					items: [
					'bold',
					'italic',
					'link',
					'undo',
					'redo',
					'math',
					'uploadImage',
					'alignment',
					'highlight'
					]
				},
				image: {
					toolbar: [
						'imageStyle:full',
						'imageStyle:side',
						'|',
						'imageTextAlternative'
					]
				},
				math: {
					engine: 'mathjax',
					lazyLoad: undefined,
					outputType: 'span',
					forceOutputType: false,
					enablePreview: true
				},
				height: 400,
				allowedContent: true
			},
			isImageChanged: false,
			lectureNoteImages: [],
			initialImage: ''
		}
	},
	mounted () {
		this.folder_path = this.$route.params.folder
		this.loadContent()
	},
	methods: {
		loadContent () {
			this.isLoading = true
			this.showErrorPageAlert = false
			const postData = {
				folder: this.folder_path,
				course_id: this.courseId,
				topic_id: this.topicId
			}
			fileMgt.fetchLectureNote(postData)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						if(response.data.note.length > 0){
							this.fform.section = []
							this.fform.tutor_image = ''
							this.fform.tutor_name = ''
							for(const item of response.data.note){
								if(parseInt(item.course_id) === parseInt(this.courseId) && parseInt(item.topic_id) === parseInt(this.topicId)){
									this.fform.tutor_name = item.tutor_name
									this.fform.tutor_image = item.tutor_pic
									this.initialImage = item.tutor_pic
									const section = {
										id: item.note_id,
										title: item.section_title,
										content: this.$generalHelper.decodeHtmlEntites(item.section_content),
										//audio: item.section_audio
									}
									this.fform.section.push(section)
								}
							}
							if(this.fform.section.length === 0){
								this.addMoreRow()
							}
						}
					}else{
						console.log(response.data.message_data)
						this.isLoading = true
						this.showErrorPageAlert = true
						this.errorMessage = response.data.message_data
					}
				})
				.catch((error) => {
					this.isLoading = true
					console.log(error.message)
					this.showErrorPageAlert = true
					this.errorMessage = 'Error Connecting To Server'
				})
		},
		addMoreRow () {
			this.fform.section.push(
				{
					id: null,
					title: '',
					content: ''
				}
			)
		},
		setImage (image) {
			if(image.length > 0){
				const index = image.indexOf('base64')
				if(index !== -1) {
					return image
				}else{
					return `${projectService.apiUrl}/load-project-image/${this.$route.params.folder}/lecture_note/${image}`
				}
			}
		},
		async handleFileChange () {
			const elem = document.getElementById('profile-image-input')
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.fform.tutor_image = image
				if(this.fform.section[0].id !== null){
					this.isImageChanged = true
				}
			}
		},
		async onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;

			//Check if the first content of the section is empty or not
			if(this.fform.section[0].title.length === 0 || this.fform.section[0].content.length === 0){
				this.$swal({
					text: 'Please enter some note before submission',
					icon: 'error'
				})
				return
			}
			this.isProcessing = true
			const postData = {
				folder: this.folder_path,
				note_content: this.fform,
				initial_image: this.initialImage
			}
			fileMgt.storeLectureNote(postData)
				.then((response) => {
					this.isProcessing = false
					if(parseInt(response.data.message_code) === 200){
						this.fform.tutor_name = ''
						this.fform.tutor_image = ''
						this.initialImage = ''
						this.isImageChanged = false
						///this.loadContent()
						this.$swal('Lecture note saved successsfull')
						this.$emit('closeForm')
					}else{
						this.$swal({
							text: response.data.message,
							icon: 'error'
						})
					}
				})
				.catch((error) => {
					this.isProcessing = false
					console.log(error.message)
					this.$swal({
						text: 'Error Connecting To Server',
						icon: 'error'
					})
				})
		},
		async deleteSection (index, item) {
			this.$swal({
				text: `Are you sure to delete this note section?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, Delete',
				allowOutsideClick: false
			}).then(async (responses) => {
				if(responses.isConfirmed) {
					if(item.id !== null){
						const postData = {
							folder: this.folder_path,
							note_id: item.id
						}
						fileMgt.deleteLectureNote(postData)
							.then((response) => {
								if(parseInt(response.data.message_code) === 200){
									this.loadContent()
									this.$swal({
										text: 'Section Deleted successsfully',
										icon: 'success'
									})
								}else{
									this.$swal({
										text: response.data.message,
										icon: 'error'
									})
								}
							})
							.catch((error) => {
								console.log(error.message)
								this.$swal({
									text: 'Error Connecting To Server',
									icon: 'error'
								})
							})
					}else{
						this.fform.section.splice(index, 1)
					}
				}
			})
		}
	}
};
</script>

<style scoped>
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: #09568d !important;
	height: auto;
	color: #ffffff;
	border-radius: 5px;
	font-weight: 400 !important;
}
.image{
	width: 100px;
	height: 100px;
	border-radius: 5px;
	position: relative;
	border: 4px solid #f4f5f8;
	background-color: #f4f5f8;
	margin-right: 20px;
}
.frame{
	height: calc(100vh - 320px);
	overflow-y: scroll;
	padding-bottom: 30px;
}
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>